.amplify-nav-bar { 
  border:0px!important; 
  color:white; 
  top:0em; 
  z-index:50; 
  position: fixed!important;
  right: 3em;
}


/*.App {
  text-align: center;
}*/

.section {
  margin-top:4em;
}

/* --- styles.css --- */
body * { transition: all 500ms; }
nav .title, nav a, nav a:hover { color:#fff; }
.card-header:hover { transform: scale(1.01, 1.01); box-shadow: 0px 5px 20px -5px rgba(0,0,0,0.75); transition: all 200ms; }
.card-header .level { width:100%; cursor:pointer; }
.card-content { overflow: auto; }
.card-content { padding:0px!important }
.card-content .content { margin:1.5em }
.card-header .note { position:relative; z-index:20; padding:1em; }
.card-header.hide + .card-content { opacity: 0; transition: all .5s 0.2s linear; height:0; }
.card-header.show + .card-content { opacity: 1; transition: all .5s linear; height:auto; overflow:hidden; }
.card-header.show .toggle-arrow { transform: rotate(-180deg); }
table { width:100% }
td.nr { width:5%; white-space: nowrap; }
td.bez { width:30%; }
td.img { width:120px; }
.reload { cursor: pointer; }
.toggle-arrow { margin-left:0.5em; }
.toolbar { top: 3em!important; z-index:23!important; padding-top: 1em; background-color: rgba(255,255,255,.95)!important}
.toolbar .navbar-menu {background: none!important}
.delivery {
  font-size:1.25em;
}
.deliverydate {
  margin-bottom:0em!important;
}
.land {
  border: solid 2px;
  width: 2.5em;
  height: 1.75em;
  border-radius: 50%;
  font-size:.8em;
  display: inline-block;
  text-align: center;
  margin-right: 1em;
}

.ul-label { border: 2px solid; border-radius: 90%; margin-left: 1em; font-size: .8em; padding: 4px; font-weight: 700!important;}
.space-left { margin-left:.5em; }

.navbar .level {width:100%}

button.filter {
  background-color: none;
}

button.filter.is-active {
  background-color: green;
  color: white;
}

div[class^="Section__container__"] {
  position: fixed;
  top: 0px;
  right: 120px;
  z-index: 100;
  padding: 1px 12px;
  text-align: right;
  background-color: rgba(180,20,20,.5);
  color:white;
}

div[class^="Form__formContainer__"] { 
  background-color: rgb(120,20,20);
  padding: 100px 60px;
}
div[class^="Form__formContainer__"] input {
  padding: 12px;
}

div[class^="Form__formContainer__"] button[class^="Button__button__"] { 
  margin-top: 4em;
  position: absolute;
  right: 70px;
  padding: 12px;
}

@media print { 
    .body { color: black;}
    .navbar.is-fixed-top { position: absolute; }
    .App-header, .amplify-nav-bar, div[class^="Section__container__"], .navbar, #toolbar, .toggle-arrow { display:none; }
    .card-content { display: block!important; }
    .land, .ul-label, .svg-inline--fa { color:black; }

}
/* --- End styles.css --- */